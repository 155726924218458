import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios'; 
import { Link } from 'react-router-dom'; 
import './Footer.css';
import { AuthContext } from '../../AccessIn/AuthContext';

const Footer = () => {
  const { iCountry, iCiudad, iRegion } = useContext(AuthContext);
 
  return (
    <div className="iFooter"> 
      <div className="container"> 
        <div className="iFooter-column">
          <div className="iFooter-pie">
            <div className='iFooter-pie-l'>
              <span className="text-14-s tbg-black2">&nbsp;chpriv&nbsp;&copy;&nbsp;2024 &nbsp;</span>
            </div>
            <div className='iFooter-pie-r'>

            <div className="Box-cajas-x">
            </div>
            <span className="text-14-s tbg-black2"> {iCountry ? <span>{iCountry}, {iRegion} ({iCiudad})</span> : <span>Ubicando...</span>}</span>
            </div> 
          </div>
        </div>
      </div>
    </div >
  );
};

export default Footer;
