import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';
import Loading from './StartComponents/Loading';
import Footer from './Pages/Footer';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AccessIn/AuthContext';

const App = React.lazy(() => import('./App'));
const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <React.StrictMode>
    <AuthProvider>
      <Suspense fallback={<Loading />}>
        <Router>
          <App />
          <Footer />
        </Router>
      </Suspense>
    </AuthProvider>
  </React.StrictMode>,
);

reportWebVitals();
