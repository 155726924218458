import ThisFooter from "../Componentes/InFooter/Footer";

function Footer() {
  return (
    <div>
      <ThisFooter />
    </div>
  );
}

export default Footer;
