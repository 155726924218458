import React, { createContext, useState, useEffect } from 'react';
import Axios from 'axios';
import CryptoJS from 'crypto-js';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [iCountry, setCountry] = useState(null);
  const [iCiudad, setCity] = useState(null);
  const [iRegion, setRegion] = useState(null);
  const [formData, setFormData] = useState({
      GloEmail: '',
      GloTelefono: '',
      GloPass: '',
  });
  const [iTr137, setITr137] = useState(null);
  const [Constantin, setConstantin] = useState(null);
  const [escrt, setCescrt] = useState(null);
  const [hasFetchedAK, setHasFetchedAK] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    setIsLoggedIn(!!authToken);
  }, []);

  useEffect(() => {
    Axios.post(`https://cloud.tupediste.com/process/location`)
      .then((response) => {
        if (response.data.reqip === false) {
          const country = '';
          const city = '';
          const region = '';
          setCountry(country);
          setCity(city);
          setRegion(region);
        } else {
          const { country, city, region } = response.data;
          setCountry(country);
          setCity(city);
          setRegion(region);
        }
      })
      .catch((error) => {
        console.error('Ups, Error');
      });
  }, []);

  const login = (token) => {
    setIsLoggedIn(true);
    localStorage.setItem('authToken', token);
    console.log('Token almacenado en localStorage:', token);
    return token;
  };

  const InStorelogout = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const authIdUni = localStorage.getItem('CodUniQ');

      await Axios.post(
        'https://inviw.cobiano.com/auth/access/sncssinvalied/',
        {
          VUniq: authIdUni,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setIsLoggedIn(false);
      localStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error('Ups, Algo salió mal', error);
    }
  };

  const logout = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const authIdUni = localStorage.getItem('CodUniQ');

      await Axios.post(
        'https://inviw.cobiano.com/auth/access/sncss/',
        {
          VUniq: authIdUni,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setIsLoggedIn(false);
      localStorage.clear();
      window.location.href = '/';
    } catch (error) {
      console.error('Ups, Algo salió mal', error);
    }
  };
  const setEncryptedFormData = (data) => {
    try {
      // Asegúrate de que Constantin y escrt están definidos y son cadenas válidas
      if (!Constantin || !escrt) {
        throw new Error('Constantin or escrt values are not set.');
      }
  
      // Parse the key and IV from hex strings
      const key = CryptoJS.enc.Hex.parse(Constantin);
      const iv = CryptoJS.enc.Hex.parse(escrt);
  
      // Encrypt the data using AES
      const encryptedMail = CryptoJS.AES.encrypt(data.GloEmail, key, { iv: iv }).toString();
      const encryptedPass = CryptoJS.AES.encrypt(data.GloPass, key, { iv: iv }).toString();
      const encryptedPhone = CryptoJS.AES.encrypt(data.GloTelefono, key, { iv: iv }).toString();
  
      // Set the encrypted form data
      setFormData({
        ...data,
        GloEmail: encryptedMail,
        GloPass: encryptedPass,
        GloTelefono: encryptedPhone,
      });
    } catch (error) {
      console.error('Error encrypting form data:', error);
    }
  };

    const fetchAK = async () => {
      if (!hasFetchedAK) {
        try {
          const response = await Axios.post(`https://cloud.tupediste.com/auth/fetchAK`);
          setITr137(response.data.iTr137);
          setConstantin(response.data.Constantin);
          setCescrt(response.data.escrt);
          setHasFetchedAK(true);
        } catch (error) {
          setITr137(false);
          setConstantin(false);
          setCescrt(false);
          setHasFetchedAK(true);
        }
      }
    };


  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        login,
        logout,
        InStorelogout,
        iCountry,
        iCiudad,
        iRegion,
        formData,
        setFormData: setEncryptedFormData,
        fetchAK,
        hasFetchedAK,
        iTr137,
        Constantin,
        escrt,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
