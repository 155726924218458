import React from 'react';
import './Loading.css'; 

function Loading() {
  return ( 
      <div className='loadding-ppl'>
        <img src="https://icloud.chpriv.com/icons/co/icon.png" alt="" /> 
      </div> 
  );
}

export default Loading;